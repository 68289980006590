import {
  AvatarScroll,
  AvatarsColItem,
  AvatarsColItemWrapper,
  Container,
  Heading,
  ScrollAvatarWrapper,
  Shadow,
  ShowCase,
  SubHeading,
  Wrapper,
  WrapperPC,
} from './style';
import IconHeart from '@/assets/images/icon-heart-circle.svg';
import IconBad from '@/assets/images/icon-bad.svg';
import Good1 from '@/assets/images/g1.png';
import Good2 from '@/assets/images/g2.png';
import Good3 from '@/assets/images/good3.svg';
import Good4 from '@/assets/images/g4.png';
import Bad1 from '@/assets/images/bad1.svg';
import Bad2 from '@/assets/images/bad2.svg';
import Bad3 from '@/assets/images/bad3.svg';
import Bad4 from '@/assets/images/bad4.svg';
import Good1PC from '@/assets/images/gpc1.png';
import Good2PC from '@/assets/images/gpc2.png';
import Good3PC from '@/assets/images/gpc3.png';
import Good4PC from '@/assets/images/gpc4.png';
import Bad1PC from '@/assets/images/b1pc.png';
import Bad2PC from '@/assets/images/b2pc.png';
import Bad3PC from '@/assets/images/b3pc.png';
import Bad4PC from '@/assets/images/b4pc.png';
import AvatarScroll1 from '@/assets/images/home-page/avt-scroll-1.jpg';
import AvatarScroll10 from '@/assets/images/home-page/avt-scroll-10.jpg';
import AvatarScroll11 from '@/assets/images/home-page/avt-scroll-11.jpg';
import AvatarScroll12 from '@/assets/images/home-page/avt-scroll-12.jpg';
import AvatarScroll13 from '@/assets/images/home-page/avt-scroll-13.jpg';
import AvatarScroll14 from '@/assets/images/home-page/avt-scroll-14.jpg';
import AvatarScroll15 from '@/assets/images/home-page/avt-scroll-15.jpg';
import AvatarScroll16 from '@/assets/images/home-page/avt-scroll-16.jpg';
import AvatarScroll17 from '@/assets/images/home-page/avt-scroll-17.jpg';
import AvatarScroll2 from '@/assets/images/home-page/avt-scroll-2.jpg';
import AvatarScroll3 from '@/assets/images/home-page/avt-scroll-3.jpg';
import AvatarScroll4 from '@/assets/images/home-page/avt-scroll-4.jpg';
import AvatarScroll5 from '@/assets/images/home-page/avt-scroll-5.jpg';
import AvatarScroll6 from '@/assets/images/home-page/avt-scroll-6.jpg';
import AvatarScroll7 from '@/assets/images/home-page/avt-scroll-7.jpg';
import AvatarScroll8 from '@/assets/images/home-page/avt-scroll-8.jpg';
import AvatarScroll9 from '@/assets/images/home-page/avt-scroll-9.jpg';
import useScreenSize from '@/hooks/useScreenSize';
import ShadowBottom from '@/assets/images/home-page/shadow-avt-scroll-bottom.png';
import ShadowTop from '@/assets/images/home-page/shadow-avt-scroll-top.png';

const avatars = [
  {
    key: 'col-1',
    items: [
      AvatarScroll1,
      AvatarScroll2,
      AvatarScroll3,
      AvatarScroll4,
      AvatarScroll5,
      AvatarScroll6,
      AvatarScroll7,
      AvatarScroll8,
    ],
  },
  {
    key: 'col-2',
    items: [
      AvatarScroll9,
      AvatarScroll10,
      AvatarScroll11,
      AvatarScroll12,
      AvatarScroll13,
      AvatarScroll14,
      AvatarScroll15,
      AvatarScroll16,
      AvatarScroll17,
    ],
  },
];

export default function UploadGuide() {
  const { isDesktop } = useScreenSize();
  // return isDesktop ? (
  //   <WrapperPC>
  //     <div className="title">Photo uploading guide</div>
  //     <div className="section">
  //       <div className="section-title">
  //         <img src={IconHeart} alt="" />
  //         Good photos
  //       </div>
  //       <div className="section-description">
  //         Use a variety of facial expressions. Selfies or close-up portraits
  //         only. Try a mix of lighting ang angles in yours shorts.
  //       </div>
  //       <div className="section-images">
  //         {[Good1PC, Good2PC, Good3PC, Good4PC].map(
  //           (item: any, index: number) => (
  //             <div key={index}>
  //               <img src={item} alt="" />
  //             </div>
  //           )
  //         )}
  //       </div>
  //     </div>
  //     <div className="section last-section">
  //       <div className="section-title">
  //         <img src={IconBad} alt="" />
  //         Bad photos
  //       </div>
  //       <div className="section-description">
  //         Group shots, full body, kids, covered faces, animals, monotonous pics,
  //         nudes.
  //       </div>
  //       <div className="section-images">
  //         {[Bad1PC, Bad2PC, Bad3PC, Bad4PC].map((item: any, index: number) => (
  //           <div key={index}>
  //             <img src={item} alt="" />
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   </WrapperPC>
  // ) : (
  //   <Wrapper>
  //     <div className="title">Photo uploading guide</div>
  //     <div className="section">
  //       <div className="section-title">
  //         <img src={IconHeart} alt="" />
  //         Good photos
  //       </div>
  //       <div className="section-description">
  //         Use a variety of facial expressions. Selfies or close-up portraits
  //         only. Try a mix of lighting ang angles in yours shorts.
  //       </div>
  //       <div className="section-images">
  //         {[Good1, Good2, Good3, Good4].map((item: any, index: number) => (
  //           <div key={index}>
  //             <img src={item} alt="" />
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //     <div className="section last-section">
  //       <div className="section-title">
  //         <img src={IconBad} alt="" />
  //         Bad photos
  //       </div>
  //       <div className="section-description">
  //         Group shots, full body, kids, covered faces, animals, monotonous pics,
  //         nudes.
  //       </div>
  //       <div className="section-images">
  //         {[Bad1, Bad2, Bad3, Bad4].map((item: any, index: number) => (
  //           <div key={index}>
  //             <img src={item} alt="" />
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   </Wrapper>
  // );

  return (
    <Container>
      <Heading>Transform your photos into expressive avatars</Heading>
      <SubHeading>
        Transform your photos into AI-powered arts that mirror your style and
        persona. Elevate your digital presence with Arrtmind today!
      </SubHeading>
      <ShowCase>
        <ScrollAvatarWrapper>
          {avatars.map((avatarsCol: any, index: number) => (
            <AvatarsColItemWrapper key={avatarsCol.key}>
              <AvatarsColItem first={index === 0}>
                {avatarsCol.items.map((avatar: any, index: number) => (
                  <AvatarScroll
                    key={avatarsCol.key + index}
                    src={avatar}
                    alt={`avatar-${index + 1}-${avatarsCol.key}`}
                  />
                ))}
              </AvatarsColItem>
            </AvatarsColItemWrapper>
          ))}
        </ScrollAvatarWrapper>
        <Shadow
          src={ShadowTop}
          alt={'shadow-top'}
          style={{ top: '-1px', left: '-1px' }}
        />
        <Shadow
          src={ShadowBottom}
          alt={'shadow-bottom'}
          style={{ bottom: '-1px', left: '-1px' }}
        />
      </ShowCase>
    </Container>
  );
}
