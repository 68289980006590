import React from 'react';

export default function BlingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={13}
      viewBox="0 0 13 13"
      fill="none"
    >
      <g clipPath="url(#clip0_1738_1769)">
        <path
          d="M5.37182 1.6269C5.54082 1.2469 6.06133 1.2284 6.26533 1.5699L6.29483 1.6269L6.81582 2.7994C7.27076 3.82286 8.02732 4.68303 8.98432 5.2649L9.14533 5.3594L10.0703 5.8794C10.1439 5.92057 10.2059 5.97948 10.2509 6.05078C10.2958 6.12207 10.3222 6.20348 10.3276 6.28758C10.333 6.37168 10.3173 6.4558 10.2819 6.53227C10.2465 6.60874 10.1925 6.67514 10.1248 6.7254L10.0708 6.7604L9.14533 7.2804C8.16902 7.82957 7.38367 8.66377 6.89432 9.6714L6.81582 9.8404L6.29483 11.0129C6.12582 11.3929 5.60532 11.4114 5.40132 11.0699L5.37182 11.0129L4.85082 9.8404C4.39589 8.81694 3.63933 7.95676 2.68232 7.3749L2.52132 7.2804L1.59632 6.7604C1.52279 6.71923 1.46073 6.66031 1.41579 6.58902C1.37085 6.51772 1.34448 6.43632 1.33906 6.35222C1.33364 6.26812 1.34935 6.184 1.38477 6.10752C1.42019 6.03105 1.47418 5.96466 1.54182 5.9144L1.59582 5.8794L2.52132 5.3594C3.49763 4.81022 4.28298 3.97603 4.77232 2.9684L4.85082 2.7994L5.37182 1.6269ZM9.94382 1.3804C10.192 1.9442 10.6153 2.41303 11.1508 2.7174C11.2308 2.7624 11.2308 2.8774 11.1508 2.9224C10.615 3.22681 10.1916 3.69584 9.94332 4.2599C9.93383 4.28113 9.91839 4.29916 9.89887 4.31182C9.87935 4.32447 9.85659 4.3312 9.83332 4.3312C9.81006 4.3312 9.7873 4.32447 9.76778 4.31182C9.74826 4.29916 9.73282 4.28113 9.72332 4.2599C9.475 3.69602 9.05158 3.22718 8.51582 2.9229C8.49774 2.91258 8.48271 2.89767 8.47225 2.87967C8.46179 2.86166 8.45629 2.84122 8.45629 2.8204C8.45629 2.79958 8.46179 2.77913 8.47225 2.76113C8.48271 2.74313 8.49774 2.72821 8.51582 2.7179C9.05174 2.41335 9.47518 1.94414 9.72332 1.3799C9.73282 1.35866 9.74826 1.34063 9.76778 1.32798C9.7873 1.31533 9.81006 1.30859 9.83332 1.30859C9.85659 1.30859 9.87935 1.31533 9.89887 1.32798C9.91839 1.34063 9.93433 1.35916 9.94382 1.3804Z"
          fill="#FFA800"
        />
      </g>
      <defs>
        <clipPath id="clip0_1738_1769">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(0.333374 0.320312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
