import { breakpoints } from '@/config/breakpoints';
import { keyframes, styled } from 'styled-components';

export const WrapperPC = styled.div`
  border-radius: 8px;
  background: var(--neutral-100, #f5f5f5);
  padding: 24px 16px;
  width: 435px;
  overflow-y: auto;
  height: 100%;
  .title {
    color: var(--secondary-black, #262626);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    letter-spacing: -1px;
  }
  .section {
    margin-top: 13.5px;
    .section-title {
      color: var(--text-primary, #18181b);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;
    }
    .section-description {
      color: var(--text-secondary, #737373);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      margin-bottom: 16px;
    }
    .section-images {
      display: flex;
      gap: 8px;
      div {
        flex: 1;
        img {
          width: 100%;
        }
      }
    }
  }
  .last-section {
    margin-top: 24px;
  }
`;

export const Wrapper = styled.div`
  .title {
    color: var(--secondary-black, #262626);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
  .section {
    margin-top: 13.5px;
    .section-title {
      color: var(--text-primary, #18181b);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 21px */
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;
    }
    .section-description {
      color: var(--text-secondary, #737373);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      margin-bottom: 16px;
    }
    .section-images {
      display: flex;
      gap: 8px;
      div {
        flex: 1;
        img {
          width: 100%;
        }
      }
    }
  }
  .last-section {
    margin-top: 24px;
    margin-bottom: 12px;
  }
`;

/// /new app page

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
`;

export const Heading = styled.div`
  color: var(--Text-Primary, #171717);
  font-family: Outfit;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  @media only screen and (max-width: 480px) {
    color: var(--Text-Primary, #171717);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
  }
`;

export const SubHeading = styled.div`
  color: var(--Text-Secondary, #737373);
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  @media only screen and (max-width: 480px) {
    color: var(--Text-Secondary, #737373);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }
`;

export const ShowCase = styled.div`
  /* width: 30%; */
  flex: 1 1 auto;
  position: relative;
  @media screen and (max-width: ${breakpoints.lg}) {
    width: 450px;
    margin: auto;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    margin: 0px;
    height: 500px;
  }
`;

export const ScrollAvatarWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 17.73px;
  /* min-width: 450px; */
  height: 100%;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 0px;
    border-radius: 12px !important;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background: #f5f5f5 !important;
    border-radius: 12px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    min-width: auto;
    width: 100%;
  }
`;

export const AvatarsColItemWrapper = styled.div`
  position: relative;
`;

export const AvatarsColItem = styled.div<{ first: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 14.57px;
  width: 100%;
  animation: ${(props) =>
      props.first ? ScrollLeftAnimated : ScrollRightAnimated}
    40s linear infinite;
  @media screen and (max-width: ${breakpoints.md}) {
    animation: ${(props) =>
        props.first ? ScrollLeftAnimatedMobile : ScrollRightAnimatedMobile}
      40s linear infinite;
  }
`;

export const AvatarScroll = styled.img`
  width: 100%;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

const ScrollLeftAnimated = keyframes`
  0% {
    top: 0%;
  }
  50% {
    top: -260%;
  }
  100% {
    top: 0%;
  }
`;
const ScrollLeftAnimatedMobile = keyframes`
  0% {
    top: 0%;
  }
  50% {
    top: -200%;
  }
  100% {
    top: 0%;
  }
`;
const ScrollRightAnimated = keyframes`
  0% {
    top: -260%;
  }
  50% {
    top: 00%;
  }
  100% {
    top: -260%;
  }
`;
const ScrollRightAnimatedMobile = keyframes`
  0% {
    top: -200%;
  }
  50% {
    top: 00%;
  }
  100% {
    top: -200%;
  }
`;

export const Shadow = styled.img`
  position: absolute;
  width: calc(100% + 2px);
`;
